<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2"><b>Step 5.</b> Next:</p>

      <p>
        Pour your crystal solution in a filter apparatus. Rinse with chilled
        <v-select
          v-model="inputs.input1"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        . Continue to draw air through the filter cake for
        <v-select
          v-model="inputs.input2"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        . Mass your product.
      </p>

      <p class="mb-2"><b>Step 6.</b></p>
      <p>
        Take a
        <v-select
          v-model="inputs.input3"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        of your product and crude product from earlier. Also take mixed melting points using some of
        the
        <v-select
          v-model="inputs.input4"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        provided.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51lbA5_Q11d',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {text: 'EtOH', value: 'EtOH'},
        {text: '~20 mL', value: '20ml'},
        {text: '$70^{\\circ}\\text{C}$', value: '70oC'},
        {text: 'crude product', value: 'crudeProduct'},
        {text: 'minimum', value: 'minimum'},
        {text: 'DI water', value: 'DIwater'},
        {text: 'drops', value: 'drops'},
        {text: 'room temperature', value: 'roomTemp'},
        {text: '15 minutes', value: '15min'},
        {text: 'melting point', value: 'meltingPoint'},
        {text: 'standards', value: 'standards'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
